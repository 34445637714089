select {
    padding: 8px;
    margin: 8px;
    font-family: "Open Sans", sans-serif;
    outline: none;
    border: none;
    option {
        font-family: "Open Sans", sans-serif;
        border: none;
    }
}