$colorAccent:#fba08b;
.college-list {
    min-width: 260px;
    max-width: 600px;
    margin: auto;
    width: 80%;
    max-height: 30vh;
    border-radius: 8px;
    padding: 6px;
    overflow: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
    .item-container {
        display: flex;
        animation: fadein 0.2s linear;
        animation-fill-mode: both;
        color: #ffffff;
        text-align: center;
        transition: all 0.2s ease-out;
        div{
            width: 100%;
            border-radius: 8px;
            background: #292929;
            padding: 16px;
            margin: 8px;
            z-index: 2;
            font-size: 18px;
            &:hover {
                background-color: $colorAccent;
                box-shadow: 0 0 10px $colorAccent;
                //color: #000;
                font-weight: 600;
            }
        }
        img {
            width: auto;
            padding: 8px;
            height: 100%;
            border-radius: 12px;
            cursor: pointer;
        }
    }
    @for $i from 0 through 10 {
        :nth-child(#{$i}n) {
            animation-delay: #{$i * 0.1}s;
        }
    }
}
.college-list::-webkit-scrollbar {
    display: none;
}
.gradientback {
    transform: translateY(-70px);
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 70px;
    background: -moz-linear-gradient(top, rgba(137, 255, 241, 0) 0%, #f5f5f5 100%); /* FF3.6+ */
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(0%, rgba(137, 255, 241, 0)),
        color-stop(100%, rgb(255, 255, 255))
    ); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, rgba(137, 255, 241, 0) 0%, #f5f5f5 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, rgba(137, 255, 241, 0) 0%, #f5f5f5 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, rgba(137, 255, 241, 0) 0%, #f5f5f5 100%); /* IE10+ */
    background: linear-gradient(to bottom, rgba(137, 255, 241, 0) 0%, #f5f5f5 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0089fff1', endColorstr='#f5f5f5',GradientType=0 ); /* IE6-9 */
}
@keyframes fadein {
    from {
        opacity: 0;
        height: 0%;
        transform: translateY(-30px);
    }
    to {
        opacity: 1;
        height: 100%;
        transform: translateY(0);
    }
}
