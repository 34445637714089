.drawer {
  height: 100%;
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 69; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  background-color: #000; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
  white-space: nowrap;
  color: #000;
  @media only screen and (max-width: 600px) {
    box-shadow: 0px 0px 20px;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  header {
    width: auto;
    margin-bottom: 20px;
    span {
      color: #fff;
      font-family: "Open Sans", sans-serif;
      display: block;
      width: 300px;
      font-size: 30px;
      text-align: center;
      font-weight: 800;
      padding-top: 32px;
    }
    div {
      &:hover {
        background: transparent;
      }
      padding: 0;
    }
  }

  div {
    display: flex;
    padding: 16px;
    &:hover {
      background: #242424;
    }
    p {
      color: #7e7e7e;
      display: flex;
      align-items: center;
      padding-left: 12px;
      text-decoration: none;
      font-size: 25px;
      transition: 0.3s;
    }
    img {
      vertical-align: middle;
      padding: 0px;
      width: 36px;
      height: 36px;
      filter: brightness(100%);
    }
  }
  .selected-dark {
    background: #e0e0e0;
    p {
      color: #000;
      font-weight: 800;
    }
    img {
      filter: brightness(0%);
    }
    &:hover {
      background: #e0e0e0;
    }
  }
  .selected-light {
    background: #000;
    p {
      color: #fff;
      font-weight: 800;
    }
    img {
      filter: brightness(200%);
    }
    &:hover {
      background: #000;
    }
  }
}
.main {
  background-color: whitesmoke;
  transition: margin-left 0.5s;
  min-width: 350px;
  padding-top: 80px;
  .header {
    top: 0;
    width: 100%;
    z-index: 10;
    position: fixed;
    float: left;
    height: 100px;
    div {
      z-index: 10;
      float: left;
      padding: 0;
      margin: 0;
    }
  }
  @keyframes fadein {
    from {
      opacity: 0;
      transform: translateY(-30px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
